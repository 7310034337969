<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="col col-auto">
        <h2 class="h5">{{ $t('obnova-hesla-form-nadpis')}}</h2>

        <div class="form-row">
          <div class="col">
            <div class="form-group mb-0">
              <label for="inputPassword" class="sr-only">{{$t('nove-heslo')}}:</label>
              <input
                type="password"
                v-model.trim="$v.p1.$model"
                @input="delayTouch($v.p1)"
                class="form-control form-control-sm"
                id="inputPassword"
                :placeholder="$t('nove-heslo')"
              />
              <small
                class="form-text text-muted invisible mt-0"
                v-if="!$v.p1.$dirty || !$v.p1.$invalid"
              >Zvolte si své nové heslo</small>
              <small
                class="form-text text-danger mt-0"
                v-if="$v.p1.$dirty && !$v.p1.required"
              >Toto pole je nutné vyplnit</small>
              <small
                class="form-text text-danger mt-0"
                v-if="$v.p1.$dirty && !$v.p1.minLength"
              >Heslo musí mít alespoň {{$v.p1.$params.minLength.min}} znaků</small>
            </div>

            <div class="form-group mb-1">
              <label for="inputPassword2" class="sr-only">{{$t('potvrzeni-hesla')}}:</label>
              <input
                type="password"
                v-model.trim="$v.p2.$model"
                @input="delayTouch($v.p2)"
                class="form-control form-control-sm"
                id="inputPassword2"
                :placeholder="$t('potvrzeni-hesla')"
              />
              <small
                class="form-text text-muted invisible mt-0"
                v-if="!$v.p2.$dirty || !$v.p2.$invalid || $v.p2.sameAsP1"
              >Potvrďte nové heslo</small>
              <small
                class="form-text text-danger mt-0"
                v-if="$v.p2.$dirty && !$v.p2.sameAsP1"
              >Hesla se musí shodovat</small>
            </div>

            <button class="btn btn-sm btn-secondary w-100" @click="nastavHeslo($v)">Nastavit</button>

            <div class="alert alert-danger mt-2" v-if="errorMessage">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const qs = require("qs");
const axios = require("axios");

import { required, minLength, sameAs } from "vuelidate/lib/validators";
const touchMap = new WeakMap();

export default {
  name: "ResetPassword2",
  data() {
    return {
      p1: "",
      p2: "",
      errorMessage: ""
    };
  },
  validations: {
    p1: {
      required,
      minLength: minLength(6)
    },
    p2: {
      required,
      sameAsP1: sameAs("p1")
    },
    fields: ['p1', 'p2']
  },
  methods: {
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    nastavHeslo($v) {
      $v.fields.$touch();
      if (!$v.fields.$invalid) {
        if (this.p1 !== this.p2) {
          this.errorMessage = this.$t("hesla-se-neshoduji");
          return;
        }

        axios
          .post(
            process.env.VUE_APP_API_ROOT_URL + "/reset-password",
            qs.stringify({ password: this.p1, token: this.$route.params.token })
          )
          .then(result => {
            if (result.status == 200) {
              this.$store.commit("setuser", result.data);
              this.$router.push({ path: "/main/motors" });
            }
          })
          .catch(err => {
            if (err.response.data.errors) {
              this.errorMessage = "";

              for (var k in err.response.data.errors) {
                console.log("chyy", k, err.response.data.errors[k][0]);
                this.errorMessage += k + " " + err.response.data.errors[k][0];
              }
            } else {
              this.errorMessage = err.response.data.message;
            }
          });
        }
      }
  }
};
</script>

<style scoped>
</style>
